import $ from 'jquery'

const $doc = $(document)

function toggleAccordionItem() {
  let el = $(this);
  if ( el.parent().parent().hasClass('active') ) {
    el.attr('aria-expanded', 'false');
  } else {
    el.attr('aria-expanded', 'true');
  }
  el.parent().next().slideToggle('fast');
  el.parent().parent().toggleClass('active');
}

function toggleSection(){
  let el = $(this);
  $(el).find('span').toggleClass('active');
  var accordion = el.parent().parent().find('.accordion');
  $(accordion).slideToggle();
}

$doc.on('click', '.js-accordion__toggle-section', toggleSection )
$doc.on('click', '.js-accordion-item button', toggleAccordionItem )
