import $ from 'jquery'

$(document).ready( initTestimonialsSlider )

function initTestimonialsSlider(){
  const sliderWrapper = $('.js-testimonials-slider');
  if (sliderWrapper.length) {
    sliderWrapper.slick({
      slidesToShow: 2,
      slidesToScroll: 1,
      arrows: true,
      dots: false,
      nextArrow: '<button type="button" class="slick-next"><svg width="20" height="54" viewBox="0 0 29 54" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 52L26 27L2 2" stroke="#7AC90E" stroke-width="6"/></svg></button>',
      prevArrow: '<button type="button" class="slick-prev"><svg width="20" height="54" viewBox="0 0 29 54" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M27 2L3 27L27 52" stroke="#7AC90E" stroke-width="6"/></svg></button>',
      responsive: [
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true,
            fade: true
          }
        }
      ]
    });
  }
}
