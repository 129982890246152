import $ from 'jquery'
import magnificPopup from 'magnific-popup'
import { getCookie, setCookie } from '/lib/cookie'
const  $doc = $(document)

$doc.ready(function () {
  if (!getCookie('announcement-popup') && $('#announcement-popup').length ) {
    setTimeout(() => {
      $.magnificPopup.open({
        items: {
          src: '#announcement-popup',
          type: 'inline',
        },
        callbacks: {
          close: function () {
            setCookie('announcement-popup', 'true', 14);
          }
        },
        showCloseBtn: false,
        mainClass: 'mfp-fade',
        removalDelay: 300,
      });
    }, 1000)
  }
})
