import $ from 'jquery'
import gsap from 'gsap'

function loadHero() {
  if ($('.js-hero-slider').length) {
    $('.js-hero-slider').slick({
      arrows: false,
      dots: true,
      autoplay: true,
      speed: 2000,
      fade: true
    });
  }

  gsap.to(".hero", {
    duration: 0.3,
    y: 0,
    autoAlpha: 1,
    opacity: 1,
    ease: "power1.out",
    delay: 0.2,
    overwrite: 'auto'
  })
}

$(document).ready( loadHero );
